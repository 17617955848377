import React, { FC } from "react";

import TextButton from "components/atoms/TextButton";

import * as styles from "./GuideCard.module.scss";
import IGuideCard from "./GuideCard.type";
import { GatsbyImage } from "gatsby-plugin-image";

const GuideCard: FC<IGuideCard> = ({ slug, excerpt, featuredImage, title }) => {
  return (
    <article className={styles.element}>
      <div className={styles.thumbnailWrapper}>
        <GatsbyImage
          className={styles.featuredImage}
          image={featuredImage.node.localFile.childImageSharp.gatsbyImageData}
          alt=""
        />
      </div>
      <div className={styles.content}>
        <h3 className="h4">{title}</h3>
        <div className={styles.excerpt} dangerouslySetInnerHTML={{ __html: excerpt }} />
        <TextButton to={`/poradniki/${slug}`} className={styles.button}>
          Czytaj więcej
        </TextButton>
      </div>
    </article>
  );
};

export default GuideCard;
