import React, { FC } from "react";

import { Link } from "gatsby";
import cn from "utils/cn";

import * as styles from "./Breadcrumbs.module.scss";
import IBreadcrumbs from "./Breadcrumbs.type";

const Breadcrumbs: FC<IBreadcrumbs> = ({ items }) => {
  return (
    <div className="container">
      <div className={styles.element}>
        {items.map((item, index) => (
          <span className={styles.item} key={index}>
            {item.href ? (
              <Link to={item.href} className="s-medium">
                {item.label}
              </Link>
            ) : (
              <span className="s-medium fw-bold c-navy">{item.label}</span>
            )}
            {index !== items.length - 1 && <span className={cn(styles.separator, "s-medium")}>/</span>}
          </span>
        ))}
      </div>
    </div>
  );
};

export default Breadcrumbs;
