import React, { FC } from "react";

import arrowRight from "assets/vector/arrow-right-black.svg";
import cn from "utils/cn";

import * as styles from "./TextButton.module.scss";
import ITextButton from "./TextButton.type";
import { Link } from "gatsby";

const TextButton: FC<ITextButton> = ({ className, to, children }) => (
  <Link to={to} className={cn(styles.element, "display-small", className)}>
    {children}
    <img src={arrowRight} />
  </Link>
);

export default TextButton;
