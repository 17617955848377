import React, { FC } from "react";
import ISectionTitle from "./SectionTitle.type";

import * as styles from "./SectionTitle.module.scss";
import cn from "utils/cn";

const SectionTitle: FC<ISectionTitle> = ({ children, className }) => (
  <div className={cn(styles.element, className)}>
    <h2 className="c-navy">{children}</h2>
    <span className={styles.line} />
  </div>
);

export default SectionTitle;
