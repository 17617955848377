import Breadcrumbs from "components/molecules/Breadcrumbs";
import GuideCard from "components/organisms/GuideCard";
import DefaultLayout from "components/templates/DefaultLayout";
import DefaultSectionTemplate from "components/templates/DefaultSectionTemplate";
import React, { FC, useState, useEffect, useMemo, useCallback, useRef } from "react";

import cn from "utils/cn";

import * as styles from "./TutorialsArchive.module.scss";
import ITutorialsArchive from "./TutorialsArchive.type";

const TutorialsArchive: FC<ITutorialsArchive> = ({ data: { posts } }) => {
  return (
    <DefaultLayout>
      <Breadcrumbs items={[{ label: "Strona główna", href: "/" }, { label: "Poradniki" }]} />
      <DefaultSectionTemplate className={styles.guides} title="Poradniki">
        <div className={styles.list}>
          {posts.map((post) => (
            <GuideCard key={post.id} {...post} />
          ))}
        </div>
      </DefaultSectionTemplate>
    </DefaultLayout>
  );
};

export default TutorialsArchive;
