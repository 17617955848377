import React, { FC } from "react";

import SectionTitle from "components/molecules/SectionTitle";
import cn from "utils/cn";

import * as styles from "./DefaultSectionTemplate.module.scss";
import IDefaultSectionTemplate from "./DefaultSectionTemplate.type";

const DefaultSectionTemplate: FC<IDefaultSectionTemplate> = ({
  title,
  children,
  className,
  ...props
}) => (
  <section className={cn(styles.element, className)} {...props}>
    <div className={cn(styles.container, "container")}>
      <SectionTitle className={styles.title}>{title}</SectionTitle>
      {children}
    </div>
  </section>
);

export default DefaultSectionTemplate;
